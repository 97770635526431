import React, { useState, useRef, createRef } from "react";
import tw from "twin.macro";
import { Button } from "reactstrap";
import Header from "components/headers/header.js";
import Footer from "components/footers/footer.js";
import { FeatuersList } from "./FeaturesList.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import Client_Management from "../images/features/Client_Management.png";
import Support_Management from "../images/features/Support_Management.png";
import Enquiry_Management from "../images/features/enquiry_mgmt0.png";
import Requirement_Management from "../images/features/requirement.png";
import black from "../images/features/enprojectnameBlack.png";
import white from "../images/features/enprojectnameWhite.png";
import Task_Management from "../images/features/Task_Management.png";
import Product_Management_Icons from "../images/features/Project_Management_Icons.png";
import HR_Management from "../images/features/HR_Management.png";
import Financial_Management from "../images/features/Financial_Management.png";
import Bug_Track from "../images/features/Bug_Tracking.png";
import Enquiry from "../images/features/Enquiry_Management.png";
import Project_Management from "../images/features/bground001.jpg";
import Asset_Management from "../images/features/assets.png"
 
const Container = tw.div`relative`;

export default () => {
  const [activeTab, setActiveTab] = useState("1");

  const elementsRef = useRef(FeatuersList.map(() => createRef()));

  const toggle = (isActiveTab) => {
    setActiveTab(isActiveTab);
  };

  return (
    <div>
      <Header onChange={toggle} isActiveTab="2" />
      <Container>
        <section className="sections home-5-bg" id="home">
          <img
            src={Project_Management}
            loading="lazy"
            alt="broken images"
            tw="w-full h-full object-cover absolute"
            className="image-bg"
          />
          <div className="home-center">
            <div className="home-desc-center">
              <div className="container">
                <div className="justify-content-center  row">
                  <div className="col-lg-7">
                    <div className="mt-40 text-center home-5-content">
                      <div className="overlay-text-container">
                        {/* <span tw="text-4xl">enProject</span>  */}
                        <h1 className=" font-weight-normal home-5-title mb-0 text-white">
                          <img src={white} height="58" width="264" className="mx-auto" alt="My Image"/> the most comprehensive way to manage your
                          projects
                        </h1>
                        <p className="text-white-70 mt-4 f-15 mb-0">
                          {" "}
                          provides powerful Integration with the most popular
                          platforms needed for the smooth functioning of your
                          Project management system.
                        </p>
                        <div className="mt-3">
                          <Link
                            to={{
                              pathname: "/Demo",
                            }}
                          >
                            <Button className="btn-primary">
                              Let's do it together
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Container>
        <div>
          <section className="section services-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="section-title">
                    <h2>Solution We Provide</h2>
                    <p>Helps lead people better, and manage things wiser.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Product_Management_Icons} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Project / Product Management</h5>
                          {/* <p>Any sales in one location is reflected in real time in the corporate module.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={HR_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>HR & Payroll Management</h5>
                          {/* <p>Recruit the right people and build an unbeatable workforce.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Financial_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Billing, Invoices & Expense Management</h5>
                          {/* <p>Manage dealer details, track each metal transactions issue & receipt, and payments.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Bug_Track} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Bug & Testcase Management</h5>
                          {/* <p>The issue tracker built to deliver issue-free software!.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Task_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Task Management</h5>
                          {/* <p>Assign tasks to users, set work hours, and track them as they are finished.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Client_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Customer Relationship Management</h5>
                          {/* <p>A satisfied customer is the best business strategy of all.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Enquiry} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Scheduled & Maintained Reports</h5>
                          {/* <p>enquiry management system is an automated software that works on your front line so that every new enquiry is handled with the care it deserves.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Support_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Support & Ticket Management</h5>
                          {/* <p>Unified calling and messaging for customer-facing teams.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Enquiry_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Enquiry & Followup Management</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Requirement_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Requirement Management</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="feature-box-1">
                        <div className="icon">
                          <img src={Asset_Management} alt="" />
                        </div>
                        <div className="feature-content">
                          <h5>Asset Management</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 section-titlee p-2">
                  <h3 style={{ color: "#085f88" }} className="mb-0 mt-2">
                   it's Not just <b >Software</b>, but a complete <b>Solution</b>
                  </h3>

                  <p className="paraline mt-3">
                  <img src={black} height="38" width="114" className="inner-common" alt="My Image"/> provides a integrated platform to manage teams and client. It covers number of modules, infrastructure and professional solutions in a single roof. Our ERP is so named because it is tool to monitor , integrate , engage and enrich your business , rather than to provide specific functionality or capabilities.  <img src={black} height="38" width="114" className="inner-common" alt="My Image"/> is an enabler for a business to boost productivity and performance, reach new markets or experience greater ROI.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
