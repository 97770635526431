import Client_Management from "../images/features/Client_Management.png";
import Support_Management from "../images/features/Support_Management.png";
import Task_Management from "../images/features/Task_Management.png";
import Product_Management_Icons from "../images/features/Project_Management_Icons.png";
import HR_Management from "../images/features/HR_Management.png";
import Financial_Management from "../images/features/Financial_Management.png";
import Bug_Track from "../images/features/Bug_Tracking.png";
import Enquiry from "../images/features/Enquiry_Management.png"
export const FeatuersList = [
  {
    name: "Project / Product Management",
    describe : "The practice of strategically driving the development, market launch, and continual support and improvement of a company's products.",
    data: [
      {
        imageSrc: Product_Management_Icons,
        title: "Product Details",
        description:
          "Any sales in one location is reflected in real time in the corporate module.",
      },
    ],
  },
  {
    name: "HR & Payroll Management",
    describe :"Recruit the right people and build an unbeatable workforce.",
    data: [
      {
        imageSrc: HR_Management,
        title: "Employee Details",
        description: "",
      },
    ],
  },
  {
    name: "Billing, Invoices & Expense Management",
    describe:"Track vendor bills and other expenses easily. Add recurring expenses. Include client expenses on invoices.",
    data: [
      {
        imageSrc: Financial_Management,
        title: "Bank Details",
        description:
        "Manage dealer details, track each metal transactions issue & receipt, and payments.",
      },
    ],
  },
  {
    name: "Bug Management & Testcase Management",
    describe:"The issue tracker built to deliver issue-free software!",
    data: [
      {
        imageSrc: Bug_Track,
        title: "Meta Data",
        description :'Tracking of orders, execution, verify status & payments.',
      },
    ],
  },
  {
    name: "Task Management",
    describe :'Assign tasks to users, set work hours, and track them as they are finished',
    data: [
      {
        imageSrc: Task_Management,
        title: "Activity",
        description:
          "Helps handle saving schemes of multiple types or users can use a preconfigured setup of schemes.",
      },
    ],
  },
  {
    name: "CRM",
    describe : 'A satisfied customer is the best business strategy of all.',
    data: [
      {
        imageSrc: Client_Management,
        title: "Client Details",
        description:
          "Accurate and Quick billing. Enabling quick billing with easy to use Mobile / Tabs Apps.",
      },
    ],
  },
  {
    name: "Scheduled & Maintained Reports",
    describe:"enquiry management system is an automated software that works on your front line so that every new enquiry is handled with the care it deserves.",
    data: [
      {
        imageSrc: Enquiry,
        title: "Enquiry Management",
        description :'Tracking of repairs, execution, verify status & payments.',
      }
    ],
  },
  {
    name: "Support & Ticket Management",
    describe : "Unified calling and messaging for customer-facing teams.",
    data: [
      {
        imageSrc: Support_Management,
        title: "Support",
        description:
          "Better to be ready before hitting rock bottom! Create professional purchase orders when stocks run low, avoid human errors and save time on corrections. Add comments to facilitate communications with your suppliers.",
      },
    ],
  },
  
 
 
 
 
];
