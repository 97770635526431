import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { components } from "ComponentRenderer.js";
import En_Project from "images/enproject Logo2.png";
import GooglePlayStore from "../../images/footer/google-app-store.png";
import AppleStore from "../../images/footer/apple-store.png";
import { ReactComponent as InstagramIcon } from "../../images/footer/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../images/footer/facebook-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/footer/youtube-icon.svg";
const Container = tw.div`relative bg-gray-200 text-gray-700  w-full`;
const Content = tw.div`relative z-0 px-10  pt-10`;
const ColumnHeading = tw.h5`uppercase font-bold mb-4`;
const LinkList = tw.ul`mt-0 text-sm font-medium p-0 mb-8`;
const LinkListItem = tw.li`mt-0`;  
const Link = tw.a`border-b-2 border-transparent hocus:border-primary-700 pb-1 transition duration-300`;
const Divider = tw.div`border-b-2 border-gray-300 w-full`;
const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between pb-5`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`flex items-center font-black border-b-0 text-2xl! m-auto sm:ml-0!`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;
const CopywrightNotice = tw.div`text-center text-sm sm:text-base mt-0 md:mt-0 font-medium text-gray-500`;
const SocialLinksContainer = tw.div`mt-0 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-100 hover:bg-primary-700 transition duration-300 mr-4 last:mr-0 no-underline`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default ({ innerPages = components.innerPages }) => {
  return (
    <Container className="footer_color">
      <Content>
        <div className="row">
          <div className="col-md-6 col-lg-3 text-center">
            <LogoImg src={En_Project} tw="mb-4" width="50%"  height="auto" alt="enProject" class="navbar-brand-full"/>
            <LinkList>
              <LinkListItem className="footer_zingerTitle">
             Our project management platform built to
              increase productivity and improve collaboration between teams and clients.                
              </LinkListItem>
            </LinkList>
          </div>
          <div className="col-md-6 col-lg-3">
            <div tw="ml-0 lg:ml-8 text-center text-center lg:text-left">
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link
                href="/features"
                >
                  Features
                </Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link
                href="/plans"
                >
                  Plans
                </Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link
                href="/demo"
                >
                  Demo
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link
                href="/contact"
                >
                  Contact
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link
                href="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link
                href="/terms-and-conditions"
                >
                  Terms & Conditions
                </Link>
              </LinkListItem>
            </LinkList>
          </div>
          </div>
          <div className="col-md-6 col-lg-3" tw=" text-center lg:text-left">
            <ColumnHeading>Follow Us</ColumnHeading>
            <SocialLinksContainer className="footer_icon">
              <SocialLink
                className="social_media_icons_xs social_media_icons_mr_0"
                href="https://www.instagram.com/invites/contact/?igsh=zkuxmsxantw7&utm_content=uzt3o00"

                target="_blank"
              >
                <InstagramIcon />
              </SocialLink>
              <SocialLink
                className="social_media_icons_xs social_media_icons_m_0"
                href="https://www.facebook.com/EnProjectPMS?mibextid=ZbWKwL"

                target="_blank"
              >
                <FacebookIcon />
              </SocialLink>
              <SocialLink
                className="social_media_icons_xs social_media_icons_ml_0"
                href="https://youtube.com/@enprojectpms?si=5H_pYYGJJIPwluwE"

                target="_blank"
              >
                <YoutubeIcon />
              </SocialLink>
            </SocialLinksContainer>
            <SocialLink
              href="/"

              target="_blank"
              style={{ background: "none" }}
            >
              <img
                src={GooglePlayStore}
                className="appStore-img social_media_icons_xs"
                alt="play_store"
              />
            </SocialLink>
            <SocialLink
              href="/"
              target="_blank"
              style={{ background: "none" }}
            >
              <img
                src={AppleStore}
                className="appStore-img social_media_icons_xs"
                alt="apple_store"
              />
            </SocialLink>
          </div>
          <div className="col-md-6 col-lg-3" tw="text-center lg:text-left">
            <ColumnHeading>For Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="tel:+917639900033">
                  +91-76399 00033 / 76399 00044
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:reachus@rasiinfotech.com" target="_blank">
                reachus@rasiinfotech.com
                </Link>
              </LinkListItem>
              {/* <img src={certificateOne} alt="Global Bussiness Award" tw="w-7/12 m-auto lg:ml-0"/> */}
            </LinkList>
          </div>
        </div>
      </Content>
      <Divider />
      <ThreeColRow>
          <LogoContainer>
            <LogoText></LogoText>
          </LogoContainer>
          <CopywrightNotice className="mt-3">
            &copy; 2016-2024{" "}
            <Link href="https://rasiinfotech.com" target="_blank">
              Rasi Infotech
            </Link> 
          </CopywrightNotice>
          <LogoText></LogoText>
        </ThreeColRow>
    </Container>
  );
};
