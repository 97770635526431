import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import Slider from "react-slick";
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Button,
} from "reactstrap";
import emailjs from "emailjs-com";
import "slick-carousel/slick/slick.css";
import Captcha from "react-numeric-captcha";
import { reactLocalStorage } from "reactjs-localstorage";
import Header from "./components/headers/header.js";
import Footer from "components/footers/footer.js";
import black from "./images/MainLandingPage/enprojectnameBlack.png";
import white from "./images/MainLandingPage/enprojectnameWhite.png";
import blue from "./images/MainLandingPage/EnprojectnameBlue.png";
import { countryCodeLists } from "./pages/CountryCodeListJson.js";
import DesignIllustration from "./images/MainLandingPage/designimg.jpg";
import Mobile_pic_En_project from "./images/MainLandingPage/Enproject _Mobile_Logo_New.png";
import Enproject_QR_Code from "./images/MainLandingPage/Enproject_QR_Code.png";
import Google_Play_store from "./images/MainLandingPage/Google_play_New.png";
import Apple_Store from "./images/MainLandingPage/Apple_Store_New.png";
import Tiger_Image from "./images/MainLandingPage/tiger_logo 2.png";
import { ReactComponent as SvgDecoratorBlob7 } from "./images/svg/svg-decorator-7.svg";
import { ReactComponent as SvgDecoratorBlob8 } from "./images/svg/svg-decorator-8.svg";
import principles_long_term from "./images/MainLandingPage/principles_long_term1.png";
import principles_focus from "./images/MainLandingPage/principles_focus.png";
import principles_customer_first from "./images/MainLandingPage/principles_customer_first.png";
import Dedicated from "./images/MainLandingPage/Dedicated.png";
import shortest_go_live from "./images/MainLandingPage/shortest_go_live.png";
import Ready_to_use from "./images/MainLandingPage/Ready_to_use.png";
import Infra_Ready from "./images/MainLandingPage/Infra_Ready.png";
import Map from "./images/MainLandingPage/Map.png";
import OnestopERP from "./images/MainLandingPage/5.png";
import GetStartedImmediately from "./images/MainLandingPage/4.png";
import ExceptionalSupport from "./images/MainLandingPage/8.png";
import TechnicalExcellence from "./images/MainLandingPage/1.png";
import InovativeConcept from "./images/MainLandingPage/3.png";
import CustomersCommitment from "./images/MainLandingPage/2.png";
import QualitySolution from "./images/MainLandingPage/7.png";
import EconomicValue from "./images/MainLandingPage/6.png";
import Centerside from "./images/MainLandingPage/23.png";
import "./styles/changeThings.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import enProject_intro_video from "./videos/demo6.mp4";
import Circle_Designs  from "./images/MainLandingPage/circle.png";

const Container = tw.div`relative w-full bg-white`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-0`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-5 lg:py-5`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center mx-10`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-bold tracking-wide text-center mt-4`;
const Subheading = tw.h5`font-bold  font-serif`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-justify`;

const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-5 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;

const OurPresenceImage = tw.img`relative `;
const OurPresenceParagraph = tw.div`text-white xl:text-lg text-justify font-bold absolute`;

 const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;
 const IllustrationContainers = tw.div`ml-0 flex justify-center lg:justify-start items-center`;


const DecoratorBlob7 = styled(SvgDecoratorBlob7)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 -z-10`}
`;

const DecoratorBlob8 = styled(SvgDecoratorBlob8)`
  ${tw`pointer-events-none -z-10 absolute right-0 bottom-0 h-64 w-64 opacity-15 text-yellow-500`}
`;
const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const Cards = tw.div`flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full w-40 h-16 sm:h-20 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);
const TextInfo = tw.div`py-2 px-10 py-2`;
const TitleReviewContainer = tw.div`flex flex-col lg:justify-between sm:flex-row sm:justify-between sm:items-center lg:items-center`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 text-justify`;
const CardSlider = styled(Slider)`
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`flex justify-center mb-1`}
  }
`;
const SocialLink = styled.a`
  ${tw`cursor-pointer rounded-full text-gray-100 hover:bg-gray-700 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Controls = tw.div` items-center`;

AOS.init({
  duration: 1000,
});

export default ({
  roundedHeaderButton,
  testimonials = [
    {
      imageSrc: principles_long_term,
      quote:
        "Years of running a profitable organisation gives us a good sense of challenges that a growing business faces. We take pride in running a sustainable business that's energized by our customer.",
      customerName: "Long-Term Commitment",
    },
    {
      imageSrc: principles_focus,
      quote:
        "Software is our passion, and we back it up with our relentless investments in Innovation and R&D. So much so that we prefer to own the complete technology stack.",
      customerName: "Focus on Innovation, Research & Development",
    },
    {
      imageSrc: principles_customer_first,
      quote:
        "Over these years, It is our customer's trust and goodwill that has helped us establish a strong position in the market. No matter the size of your business, we provide solutions to grow.",
      customerName: "Customer-Centric Philosophy",
    },
  ],
  onboardingContent = [
  
    {
      imageSrc: Ready_to_use,
      quote:
        " solution is ready to use thus no time is wasted to learn the nuances of the software business.",
      title: "Ready To Use",
      headColor: "white",
      img:true

    },
    {
      imageSrc: Dedicated,
      quote:
        "Professionally managed dedicated support ensuring faster solution.",
      title: "Dedicated Support",
      headColor: "white",

    },
    {
      imageSrc: Infra_Ready,
      quote:
        "Cloud based software ensures no heavy expenses on infrastructure in premise.",
      title: "Infra Ready",
      headColor: "white",

    },
    {
      imageSrc: shortest_go_live,
      quote:
        " has the shortest go live process which can be as short as 3days.",
      title: "Shortest Go-Live",
      headColor: "white",
      img:true

    },
  ],
  
  ourPresenceTitle = [
    {
      title: "Stitched in India",
    },
    {
      title: "Knitted for World",
    },
  ],
 
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");


  const [loginModal, setLoginModal] = useState(false);
  const [name, setName] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedCountryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [remarks, setRemarks] = useState("");
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [isNotificationModal, setNotificationModal] = useState(false);
  const [invalidFlag, setInvalidFlag] = useState({
    name: false,
    phone: false,
    email: false,
    remarks: false,
  });

  useEffect(() => {
    getCountryCodeList();
  }, []);

  const getCountryCodeList = () => {
    const countryCodeListData = [];
    countryCodeListData.push({ value: "", label: "Select" });
    countryCodeLists.forEach((item) => {
      countryCodeListData.push({
        dial_code: item.dial_code,
        digits: item.digits ? item.digits : 15,
        value: item.dial_code,
        label: `${item.code}(${item.dial_code}) `,
        flag: item.flag,
        code: item.code,
      });
    });
    setCountryCodeList(countryCodeListData);
    const loginModal = reactLocalStorage.get("loginModal");
    if (!loginModal) {
      setTimeout(() => {
        setLoginModal(true);
        reactLocalStorage.set("loginModal", true);
      }, 10000);
    }
  };



  const toggle = () => {
    setLoginModal(!loginModal);
    setNotificationModal(false);
    setName("");
    setCountryCode("+91");
    setPhone("");
    setEmail("");
    setRemarks("");
    setCaptchaSuccess(false);
    setInvalidFlag({
      name: false,
      phone: false,
      email: false,
      remarks: false,
    });
  };

  const toggleNotificationModal = () => {
    setNotificationModal(false);
  };

  const submitForm = () => {
    postAjaxRequest();
  };

  const postAjaxRequest = () => {
    if (validateFields() && captchaSuccess) {
      let phoneNo = "";
      if (selectedCountryCode) {
        phoneNo += `${selectedCountryCode}${phone}`;
      } else {
        phoneNo = phone;
      }
      const templateParams = {
        name: name,
        phone: phoneNo,
        remarks: remarks,
        email,
      };
      emailjs
        .send(
          "service_m6obie7",
          "template_aeildhh",
          templateParams,
          "user_6n2BpAAMVe0g19wywmeBb"
        )
        .then(
          (data) => {
            setNotificationModal(true);
            setName("");
            setCountryCode("+91");
            setPhone("");
            setEmail("");
            setRemarks("");
            setCaptchaSuccess(false);
            setLoginModal(false);
            setInvalidFlag({
              name: false,
              phone: false,
              email: false,
              remarks: false,
            });
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  const validateField = (
    fieldName: string,
    fieldValue: any,
    isRequired: boolean
  ) => {
    let isValid = false;
    if (isRequired === true) {
      isValid =
        fieldValue.toString().trim().length === 0
          ? false
          : fieldName.test(fieldValue.toString());
    } else {
      isValid =
        fieldValue.toString().trim().length > 0
          ? fieldName.test(fieldValue.toString())
          : true;
    }
    return isValid;
  };

  const validateFields = () => {
    let isValid = true;

    if (!validateField(/^[a-zA-Z0-9. ]+$/i, name, true)) {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: true }));
      isValid = false;
    } else {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: false }));
    }

    if (selectedCountryCode) {
      const telCodeFind = countryCodeList.filter(function (obj) {
        return obj.dial_code === selectedCountryCode;
      });
      const countryCodeDigits =
        telCodeFind.length > 0 ? telCodeFind[0].digits : 15;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        (countryCodeDigits !== phone.length && telCodeFind.length > 0)
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
        isValid = false;
      } else {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: false }));
      }
    } else {
      const countryCodeDigits = 10;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        countryCodeDigits !== phone.length
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
      }
    }

    return isValid;
  };


  return (
    // <AnimationRevealPage>
    <div>
      <Header roundedHeaderButton={roundedHeaderButton} isActiveTab="1" />
      <Container className="TwoColumn_bg">
        <br/>
        <br/>
        <TwoColumn className="homepage textandheader">
          <LeftColumn className="homepage2">
            <Heading tw="my-6">Our project management platform built to</Heading>
            <p className="home_sub_heading" md="9" sm="12" xs="12">            
            increase productivity and improve collaboration between teams and clients.
            </p> 
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
         <br/>
        <br/>
      </Container>
      <Container className="homepage changePage_bg">
        <Heading className="waviy_padding">
          <div className="waviy text-white" data-aos="fade-right">
          Engineered For Excellence 
          </div>
        </Heading>
        <Paragraph className="px-5" data-aos="fade-left">
          <div className="homepage2text text-white">
            <p>
              <span>
                 <img src={white} height="38" width="144" className="inner-common" alt="My Image" /></span>
             an all in one cloud-based work management software that’s flexible enough for businesses with teams of all sizes. A good collaborative tool for teams and clients to save data and get better  insights . It also makes it easy to assign tasks to teams and manage projects & financials wiser. It is designed to help project managers, plan, monitor and get reports on projects to collaborate and work more productively together.
            </p>
          </div>
        </Paragraph>
        <Row className="w-100 margin-auto">
        <Col md="12" sm="12" xs="12" className="p-0">
            <FormGroup className="changePage_container">
              <div className="changePage_center_part">
                <img src={Centerside} alt="Center side" />
              </div>
              <div className="changePage_polygon rotate1">
                <div className="changePage_polygon_bg">
                  <img src={GetStartedImmediately} alt="one stop erp" />
                </div>
                <span className="rotate1_text">Get Start Immediately</span>
              </div>
              <div className="changePage_polygon rotate2">
                <div className="changePage_polygon_bg">
                  <img src={OnestopERP} alt="one stop erp" />
                </div>
                <span className="rotate2_text">One Stop all Module ERP</span>
              </div>
              <div className="changePage_polygon rotate3">
                <div className="changePage_polygon_bg">
                  <img src={InovativeConcept} alt="one stop erp" />
                </div>
                <span className="rotate3_text">Long Term Innovative Concepts</span>
              </div>
              <div className="changePage_polygon rotate4">
                <div className="changePage_polygon_bg">
                  <img src={CustomersCommitment} alt="one stop erp" />
                </div>
                <span className="rotate4_text">Customer Commitment</span>
              </div>
              <div className="changePage_polygon rotate5">
                <div className="changePage_polygon_bg">
                  <img src={QualitySolution} alt="one stop erp" />
                </div>
                <span className="rotate5_text">Customised Quality Solution</span>
              </div>
              <div className="changePage_polygon rotate6">
                <div className="changePage_polygon_bg">
                  <img src={EconomicValue} alt="one stop erp" />
                </div>
                <span className="rotate6_text">Highly Valued Economic Software</span>
              </div>
              <div className="changePage_polygon rotate7">
                <div className="changePage_polygon_bg">
                  <img src={TechnicalExcellence} alt="one stop erp" />
                </div>
                <span className="rotate7_text">Technical Excellence ERP</span>
              </div>
              <div className="changePage_polygon rotate8">
                <div className="changePage_polygon_bg">
                  <img src={ExceptionalSupport} alt="one stop erp" />
                </div>
                <span className="rotate8_text">All Time Exceptional Customer Support</span>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Container>
      <Container >
        <ContentWithPaddingXl>
          <SectionHeading>
            The core values and principles that drive us
          </SectionHeading>
          <Testimonials>
            {testimonials.map((testimonial, index) => (
              <TestimonialContainer key={index}>
                <Testimonial>
                  <Image src={testimonial.imageSrc} />
                  <Subheading>{testimonial.customerName}</Subheading>
                  <Quote>"{testimonial.quote}"</Quote>
                </Testimonial>
              </TestimonialContainer>
            ))}
          </Testimonials>
        </ContentWithPaddingXl>
        <DecoratorBlob7 />
        <DecoratorBlob8 />
      </Container>
  
      <Container className="ourpresence_bg mt-5">
        <br/>
        <Content>
          <SectionHeading className="py-4" style={{ color:'white' }}>
            Onboarding Commitment
          </SectionHeading>
          <Row className="pb-5 w-100 m-auto">
            {onboardingContent.map((item, index) => (
              <>
                <Col md="6" sm="12" xs="12" tw="mb-4 lg:mb-0">
                  <Row className="onboard_container m-auto">
                    <Col md="3" sm="12" xs="12">
                      <img
                        src={item.imageSrc}
                        alt={item.title}
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col md="9" sm="12" xs="12">
                      <div
                        className="onboard_sub_heading"
                        style={{ color: item.headColor }}
                      >
                        {item.title}
                      </div>
                       <div className="onboard_paragraph">
                        {item.img?(
                           <p>
                           <span>
                              <img src={white} height="38" width="144" className="inner-common" alt="My Image" /></span>
                              {item.quote}
                         </p>
                        ): (
                          <p>
                              {item.quote}
                         </p>
                        )}
                     </div>
                    </Col>
                  </Row>
                </Col>
                <Col md="6"></Col>
                <Col md="6"></Col>
              </>
            ))}
          </Row>
        </Content>
        <br/>
      </Container>
     
      {/* <Container className="TwoColumn_bg">
        <br/>
        <TwoColumn className="homepage textandheader">
          <LeftColumn className="homepage2">
            <Heading tw="my-6">Log every minute with timesheets</Heading>
            <Paragraph>
            Log billable and non-billable hours with the Projects timesheet module. Record every minute of your hard work, either manually or with timers, and our built-in integration with our Invoice will automatically generate invoices from your timesheets.
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full"
                src={TimeSheet_Im<br/>
      <br/>
      <br/>
      <br/>g}
                alt="TimeSheet_Img"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <br/>
      </Container> */}
     

      <Container className="mobile_Text">
      <Heading className="waviy_padding">
          <div className="waviy mobile_Text" data-aos="fade-right">
          Anywhere, Anytime Solution         
         </div>
        </Heading>
        <TwoColumn>
        <LeftColumn className="mx-3">
        <IllustrationContainers >
            <img  tw="w-40 h-40 mt-6 mx-0" 
             src={Enproject_QR_Code} 
             alt="Qr_code"/>
        </IllustrationContainers>
              <h3 tw="mt-3" className="mobile_Text">Scan Me Now</h3>
            <div tw="my-6 font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-blue-700 leading-tight">Mobile app for better monitoring</div>
            <Paragraph className="mobile_Text">
            Whether on the road or attending a conference, or away on holiday, our project management apps will allow to monitor projects to stay productive.<img src={blue} height="38" width="114" className="inner-common" alt="My Image"/>is available on iOS, Android, and iPad , making monitoring handy & effective.
            </Paragraph>
            <Row tw="mt-0 flex">
             <Col>
             
             <Link
             to={{
              pathname: "/",
            }}
               target="_BLANK">
                 <img width="200px" height="300px" src={Google_Play_store} alt="Google Play" className="ms-auto px-8 static cursor-pointer"/>
            </Link>
            </Col>
           <Col>
           <Link 
           to={{
            pathname:"/"
            }} 
               target="_BLANK">
                  <img width="200px" height="300px" src={Apple_Store} alt="Apple Store" className="px-8 inline-block"/>
          </Link>
          </Col>
           </Row>
        </LeftColumn>
        <RightColumn xs="12" sm="12" md="12">
          <IllustrationContainer >
            <img tw="w-7/12	 h-auto m-auto mt-12 relative justify-center" 
            src={Mobile_pic_En_project}
            alt="mobile_pics"/>
          </IllustrationContainer>
        </RightColumn>
        </TwoColumn>
      </Container>
    



        <Container className="TwoColumn_bg" md="6" sm="12" xs="12">
          <br/>
        <TwoColumn className="homepage textandheader">
          <LeftColumn className="homepage2">
            <h1 className="mx-3" tw="font-bold">Engineered For Excellence</h1>
              <div md="6" sm="12" xs="12">
               <img
               className="circle"
               src={Circle_Designs}
               alt="circleimgage"
               />
            </div>
            <p className="home_sub_heading px-3">Collaboration made easy & efficient</p>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
               <video 
               className="video_size"
               controls
               src={enProject_intro_video}
               />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <br/>
      </Container>
      <Container className="ourpresence_bg">
        <Content >
            <Col md="12" sm="12" xs="12" tw="w-full p-0">
              {/* <SectionHeading className="py-3 sm:py-3 md:py-4 lg:py-4 m-0 text-white">
              </SectionHeading> */}
              <OurPresenceImage src={Map} />
              <OurPresenceParagraph className="ourpresence_text" style={{marginLeft:'30px'}}>
                  <div tw="py-2 sm:py-5 px-2 sm:px-3 md:py-8 lg:py-10">
                    {ourPresenceTitle.map((items, index) => (
                      <p key={index} className="mb-1 ourpresence_header">
                        {items.title}
                      </p>
                    ))}
                  </div>
                  <div>
                    <img className="ourpresence_lion" src={Tiger_Image} alt="Made_In_India"/>
                  </div>
              </OurPresenceParagraph>
            </Col>
        </Content>
      </Container>
      <Footer />
      {/* Welcome modal start */}
      <Modal isOpen={loginModal}>
        <ModalHeader toggle={toggle} className="p-2">
          <h5 className="text-center mb-0">Schedule a demo</h5>
        </ModalHeader>
        <ModalBody className="px-5">
          <Form>
            <Row className="mb-2">
              <Col md="4">
                <Label for="name">Name :</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  invalid={invalidFlag.name}
                  placeholder="Name"
                  bsSize="sm"
                  onChange={(e) => {
                    setName(e.target.value);
                    setInvalidFlag((invalidFlag) => ({
                      ...invalidFlag,
                      name: false,
                    }));
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4">
                <Label for="phone">Mobile :</Label>
              </Col>
              <Col md="8">
                <InputGroup Row>
                  <Col md="4" sm="4" xs="4">
                    <Input
                      type="select"
                      id="selectedCountryCode"
                      bsSize="sm"
                      value={selectedCountryCode}
                      style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      {countryCodeList.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md="8" sm="8" xs="8">
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      value={phone}
                      invalid={invalidFlag.phone}
                      placeholder="mobile"
                      bsSize="sm"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setInvalidFlag((invalidFlag) => ({
                          ...invalidFlag,
                          phone: false,
                        }));
                      }}
                      style={{ borderTopLeftRadius: 0, borderLeftRadius: 0 }}
                    />
                  </Col>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4">
                <Label for="email">Email :</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  invalid={invalidFlag.email}
                  placeholder="Email"
                  bsSize="sm"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setInvalidFlag((invalidFlag) => ({
                      ...invalidFlag,
                      email: false,
                    }));
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4">
                <Label for="Text">Requirement Details :</Label>
              </Col>
              <Col md="8">
                <Input
                  type="textarea"
                  name="remarks"
                  id="remarks"
                  value={remarks}
                  invalid={invalidFlag.remarks}
                  placeholder="Requirement Details"
                  bsSize="sm"
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4"></Col>
              <Col md="8">
                <Captcha onChange={(status) => setCaptchaSuccess(status)} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4"></Col>
              <Col md="8">
                <Button
                  block
                  size="sm"
                  color="info"
                  className="btn"
                  onClick={submitForm}
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* Welcome modal end */}
      {/* Notification modal start */}
      <Modal isOpen={isNotificationModal}>
        <ModalHeader className="p-2 text-center modal-success">
          Send Mail
        </ModalHeader>
        <ModalBody className="py-3 text-center">
          <div>
            <i
              className="fa fa-thumbs-up"
              color="success"
              style={{ fontSize: "50px", color: "#4dbd74" }}
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <h4>Thank you!</h4>
          </div>
          <div>
            Your message has been successfully sent. We will contact you very
            soon!
          </div>
        </ModalBody>
        <ModalFooter>
          <Col md="4">
            <Button
              block
              size="sm"
              color="info"
              className="btn"
              onClick={toggleNotificationModal}
            >
              OK
            </Button>
          </Col>
        </ModalFooter>
      </Modal>

      {/* Notification modal end */}
    </div>

  );
};
