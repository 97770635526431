import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Captcha from "react-numeric-captcha";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/header.js";
import Footer from "components/footers/footer.js";
import {
  Input,
  InputGroup,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import emailjs from "emailjs-com";
import { countryCodeLists } from "./CountryCodeListJson.js";

const textOnLeft = true;
const Container = tw.div`relative p-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row mx-auto justify-between`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12`,
  props.textOnLeft
    ? tw`md:mr-6 lg:mr-12 md:order-first`
    : tw`md:ml-6 lg:ml-12 md:order-last`,
]);
const TextContent = tw.div`text-center md:text-left`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-bold tracking-wide text-center`;
const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-white`;
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;

export default () => {
  const [name, setName] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedCountryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [remarks, setRemarks] = useState("");
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [isNotificationModal, setNotificationModal] = useState(false);
  const [invalidFlag, setInvalidFlag] = useState({
    name: false,
    phone: false,
    email: false,
    remarks: false,
  });

  useEffect(() => {
    getCountryCodeList();
  }, []);

  const getCountryCodeList = () => {
    const countryCodeListData = [];
    countryCodeListData.push({ value: "", label: "Select" });
    countryCodeLists.forEach((item) => {
      countryCodeListData.push({
        dial_code: item.dial_code,
        digits: item.digits ? item.digits : 15,
        value: item.dial_code,
        label: `${item.code}(${item.dial_code}) `,
        flag: item.flag,
        code: item.code,
      });
    });
    setCountryCodeList(countryCodeListData);
  };

  const toggleNotificationModal = () => {
    setNotificationModal(false);
  };

  const submitForm = () => {
    postAjaxRequest();
  };

  const postAjaxRequest = () => {
    if (validateFields() && captchaSuccess) {
      let phoneNo = "";
      if (selectedCountryCode) {
        phoneNo += `${selectedCountryCode}${phone}`;
      } else {
        phoneNo = phone;
      }
      const templateParams = {
        name: name,
        phone: phoneNo,
        remarks: remarks,
        email: "",
      };
      emailjs
        .send(
          "service_m6obie7",
          "template_aeildhh",
          templateParams,
          "user_6n2BpAAMVe0g19wywmeBb"
        )
        .then(
          (data) => {
            setNotificationModal(true);
            setName("");
            setCountryCode("+91");
            setPhone("");
            setEmail("");
            setRemarks("");
            setCaptchaSuccess(false);
            setInvalidFlag({
              name: false,
              phone: false,
              remarks: false,
            });
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  const resetForm = () => {
    setName("");
    setCountryCode("+91");
    setPhone("");
    setEmail("");
    setRemarks("");
    setCaptchaSuccess(false);
    setInvalidFlag({
      name: false,
      phone: false,
      remarks: false,
    });
  }

  const validateField = (
    fieldName: string,
    fieldValue: any,
    isRequired: boolean
  ) => {
    let isValid = false;
    if (isRequired === true) {
      isValid =
        fieldValue.toString().trim().length === 0
          ? false
          : fieldName.test(fieldValue.toString());
    } else {
      isValid =
        fieldValue.toString().trim().length > 0
          ? fieldName.test(fieldValue.toString())
          : true;
    }
    return isValid;
  };

  const validateFields = () => {
    let isValid = true;

    if (!validateField(/^[a-zA-Z0-9. ]+$/i, name, true)) {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: true }));
      isValid = false;
    } else {
      setInvalidFlag((invalidFlag) => ({ ...invalidFlag, name: false }));
    }

    if (selectedCountryCode) {
      const telCodeFind = countryCodeList.filter(function (obj) {
        return obj.dial_code === selectedCountryCode;
      });
      const countryCodeDigits =
        telCodeFind.length > 0 ? telCodeFind[0].digits : 15;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        (countryCodeDigits !== phone.length && telCodeFind.length > 0)
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
        isValid = false;
      } else {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: false }));
      }
    } else {
      const countryCodeDigits = 10;
      if (
        !validateField(/^[0-9]+$/, phone, true) ||
        countryCodeDigits !== phone.length
      ) {
        setInvalidFlag((invalidFlag) => ({ ...invalidFlag, phone: true }));
      }
    }

    return isValid;
  };

  return (
    <div>
      <Header isActiveTab="5" />
      <Container className="ptSans">
        <Heading className="text-center">
         <span className="text-black">Contact</span>  <span style={{color:'#085f88'}}>Information.</span>
        </Heading>
        <TwoColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent className="textandheader homepage2 mb-2">
              <Row>
                <Col md="1"></Col>
                <Col md="11">
                  <Card
                    style={{
                      borderRadius: "25px 0px 25px 0px",
                      background: "#D4E7F8",
                    }}
                  >
                    <CardBody className="card-body" style={{padding:'1.5rem'}}>
                      <Form>
                        <FormGroup className="mb-1">
                          <Label for="name">Name :</Label>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            invalid={invalidFlag.name}
                            placeholder="Name"
                            bsSize="sm"
                            onChange={(e) => {
                              setName(e.target.value);
                              setInvalidFlag((invalidFlag) => ({
                                ...invalidFlag,
                                name: false,
                              }));
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-1">
                          <Label for="phone">Mobile Number :</Label>
                          <InputGroup Row>
                            <Col md="4" sm="3" xs="4">
                              <Input
                                type="select"
                                id="selectedCountryCode"
                                bsSize="sm"
                                value={selectedCountryCode}
                                className="col-md-3"
                                onChange={(e) => setCountryCode(e.target.value)}
                                style={{
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              >
                                {countryCodeList.map((item) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                            <Col md="8" sm="9" xs="8">
                              <Input
                                type="text"
                                name="phone"
                                id="phone"
                                value={phone}
                                invalid={invalidFlag.phone}
                                placeholder="Phone"
                                bsSize="sm"
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setInvalidFlag((invalidFlag) => ({
                                    ...invalidFlag,
                                    phone: false,
                                  }));
                                }}
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderLeftRadius: 0,
                                }}
                              />
                            </Col>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-1">
                          <Label for="email">Email :</Label>
                          <Input
                            type="text"
                            name="email"
                            id="email"
                            value={email}
                            invalid={invalidFlag.email}
                            placeholder="Email"
                            bsSize="sm"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setInvalidFlag((invalidFlag) => ({
                                ...invalidFlag,
                                email: false,
                              }));
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="Text">Requirement Details :</Label>
                          <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            value={remarks}
                            invalid={invalidFlag.remarks}
                            placeholder="Requirement Details"
                            bsSize="sm"
                            onChange={(e) => setRemarks(e.target.value)}
                          />
                        </FormGroup>
                        <Row className="rnc-center">
                          <Col md="12">
                            <Captcha
                              onChange={(status) => setCaptchaSuccess(status)}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Row className="mb-2">
                        <Col md="6">
                          <Button
                            block
                            size="sm"
                            color="danger"
                            className="btn"
                            onClick={resetForm}
                          >
                            <b>Reset</b>
                          </Button>
                        </Col>
                        <Col md="6">
                          <Button
                            block
                            size="sm"
                            color="primary"
                            className="btn"
                            onClick={submitForm}
                          >
                            <b>Submit</b>
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </TextContent>
          </TextColumn>
          <TextColumn className="mb-2">
            <Address
              className="card p-3"
              style={{
                background: "#B9E4F7",
                color: "#243e63",
                fontWeight: "700",
              }}
            >
              <AddressLine>Rasi Infotech</AddressLine>
              <AddressLine>No.146/3, Amaravathy Nagar,</AddressLine>
              <AddressLine>Bangalore Highway,</AddressLine>
              <AddressLine>Mamangam, Salem – 636302.</AddressLine>
              <AddressLine>Cell : +91 76399 00033 / 76399 00044</AddressLine>
              <AddressLine>Mail : reachus@rasiinfotech.com</AddressLine>
            </Address>
            <div className="mx-auto mb-2">
              <iframe
                className="iframeContact shadow"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                width="100%"
                height="330"
                title="IFrame"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7814.00110472866!2d78.10672826484314!3d11.694372874941685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x83c01f3855c7e06b!2sRasi+Infotech!5e0!3m2!1sen!2sin!4v1550034206543"
              ></iframe>
            </div>
          </TextColumn>
        </TwoColumn>
      </Container>
      <Footer />
      {/* Notification modal start */}
      <Modal isOpen={isNotificationModal}>
        <ModalHeader className="p-2 text-center modal-success">
          Send Mail
        </ModalHeader>
        <ModalBody className="py-3 text-center">
          <div>
            <i
              className="fa fa-thumbs-up"
              color="success"
              style={{ fontSize: "50px", color: "#4dbd74" }}
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <h4>Thank you!</h4>
          </div>
          <div>
            Your message has been successfully sent. We will contact you very
            soon!
          </div>
        </ModalBody>
        <ModalFooter className="p-0">
          <Col md="4">
            <Button
              block
              size="sm"
              color="info"
              className="btn"
              onClick={toggleNotificationModal}
            >
              OK
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
      {/* Notification modal end */}
    </div>
  );
};
