import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import logo from "../../images/enproject Logo2.png";
// import award from "../../images/certificate/award.webp";
// import close from "../../images/certificate/close.webp";
// import certificateTwo from "../../images/certificate/Zinger ERP- C.webp"
import '../../styles/module.css';

const Header = tw.header`flex justify-between items-center px-4 bg-gradient-to-r from-blue-900 to-blue-700 text-white`;

export const NavLinks = tw.div`inline-block`;
/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-blue-900 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default (props,{
  roundedHeaderButton = false,
  className,
  collapseBreakpointClass = "lg"
}) => {
  const [activeTab] = useState(props.isActiveTab !== undefined && props.isActiveTab ? props.isActiveTab : '1');
  // const [animate, setAnimate] = useState(false);

  // const handleClick = () => setAnimate(!animate);

  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const defaultLinks = [
    <NavLinks key={1} className="ptSans">
      <NavLink className={activeTab === '1' ? 'active_nav_bar': ''} href="/">Home</NavLink>
      <NavLink className={activeTab === '2' ? 'active_nav_bar': ''} href="/features">Features</NavLink>
      {/* <NavLink className={activeTab === '3' ? 'active_nav_bar': ''} href="/plans">Plans</NavLink> */}
      {/* <NavLink className={activeTab === '4' ? 'active_nav_bar': ''} href="/testimonials">Testimonials</NavLink> */}
      <NavLink className={activeTab === '5' ? 'active_nav_bar': ''} href="/contact">Contact</NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} className="font-weight-bolder primaryLinkStyle" href="/demo">
        Demo
      </PrimaryLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} width="27%" alt="En_project" />
    </LogoLink>
  );
  return (
    <>
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {defaultLogoLink}
        {defaultLinks}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {defaultLogoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {defaultLinks}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
    <div  tw="w-3/4 sm:w-2/4 md:w-2/5 lg:w-1/3 fixed z-50 bottom-0 text-left" style={{bottom: "128px",right:"0px"}} >
      {/* {animate &&
    <img src={certificateTwo} alt="Global Bussiness Award" className="w-100 transition" style={{border:"1px solid rgb(79 111 250 / 47%)",boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}/>
      } */}
   {/* <div className="icon">
        {animate ? 
        <img src={close} alt="Global Bussiness Award"  className="animate"   onClick={handleClick} />
        :
        <img src={award} alt="Global Bussiness Award" className="animate" onClick={handleClick} />
         }
   </div> */}
    </div>
    </>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
